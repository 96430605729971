document.addEventListener 'DOMContentLoaded', (e) ->
  modalOpenTriggers = document.querySelectorAll '.js-open-modal'
  for trigger in modalOpenTriggers
    do ->
      trigger.addEventListener 'click', (e) ->
        document.body.classList.add 'js-modal-open'
      , false

  modalCloseTriggers = document.querySelectorAll '.js-close-modal'
  for trigger in modalCloseTriggers
    do ->
      trigger.addEventListener 'click', (e) ->
        document.body.classList.remove 'js-modal-open'
      , false

